const DEFAULT_MIN_AI_PROBABILITY = 0.5

function getLikelihood(prediction, view, species, isCropped, aiConfig) {
  try {
    const config = get_condition_config(aiConfig, prediction, species, isCropped)
    if (!config) {
      // console.log(`WARNING no config for ${species} ${prediction.ml_name} (${isCropped ? 'cropped' : 'uncropped'})`)
      return
    }

    // medical_image_permutation predictions are always in view
    const inBodyRegion = isCropped || !config.views.length || config.views.indexOf(view) > -1
    if (!inBodyRegion) return

    if (prediction.probability > (config.p_th || DEFAULT_MIN_AI_PROBABILITY)) {
      return 'likely'
    } else if (config?.possible_p_th && prediction.probability > config.possible_p_th) {
      return 'possible'
    } else if (config?.potential_p_th && prediction.probability > config.potential_p_th) {
      return 'potential'
    }
    return
  } catch (e) {
    console.log('ERROR ' + e)
    return
  }
}

function get_condition_config(aiConfig, prediction, species, isCropped) {
  if (isCropped && aiConfig['combined-yolo'][prediction.ml_name]) {
    return aiConfig['combined-yolo'][prediction.ml_name]
  }

  const key = isCropped ? species + '-yolo' : species

  try {
    return aiConfig[key][prediction.ml_name]
  } catch (e) {}
}

function get_predictions_for_image(aiConfig, image, species) {
  let results = []

  const predictions = image.ai_predictions_denormalized || []
  const positive = predictions
    .map(function (p) {
      const likelihood = getLikelihood(p, image.view.display_name, species, false, aiConfig)
      return { prediction: p, image: image, likelihood: likelihood }
    })
    .filter(function (p) {
      return p.likelihood
    })

  results.push.apply(results, positive)

  image['medical_image_permutations'].forEach(function (permutation) {
    if (aiConfig['condition-yolo'] && aiConfig['condition-yolo'][permutation['label']]) {
      const likelihood = get_likelihood_yolo(
        aiConfig['condition-yolo'][permutation['label']],
        image.view?.display_name,
        permutation.confidence
      )
      if (likelihood) {
        const prediction = {
          ml_name: permutation['label'],
          probability: permutation.confidence,
          bounding_box: permutation.bounding_box,
        }
        results.push.apply(results, [{ prediction: prediction, permutation: permutation, likelihood: likelihood }])
      }
    }

    const predictions = permutation.ai_predictions_denormalized || []
    const positive = predictions
      .map(function (p) {
        const likelihood = getLikelihood(p, permutation.label, species, true, aiConfig)
        return { prediction: p, permutation: permutation, likelihood: likelihood }
      })
      .filter(function (p) {
        return p.likelihood
      })

    results.push.apply(results, positive)
  })

  return results
}

function get_likelihood_yolo(config, view, confidence) {
  const inBodyRegion = !config.views || !config.views.length || config.views.indexOf(view) > -1
  if (!inBodyRegion) return

  if (config.p_th && confidence > config.p_th) {
    return 'likely'
  } else if (config.possible_p_th && confidence > config.possible_p_th) {
    return 'possible'
  } else if (config.potential_p_th && confidence > config.potential_p_th) {
    return 'potential'
  }
}

function get_predictions_for_case(aiConfig, c) {
  const species = c['patient']['species']
  let results = []
  c['medical_images'].forEach(function (image) {
    results.push.apply(results, get_predictions_for_image(aiConfig, image, species))
  })
  return results
}

module.exports = { get_predictions_for_case, get_predictions_for_image, get_condition_config, getLikelihood }
